import React, { Component, Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import moment from 'constants/moment'
import { Model } from 'survey-core'
import { get, isNil, omitBy, identity } from 'lodash'
import { Loader } from 'components'
import tracker from 'utils/trackers'
import OnboardingSteps from 'page/common/OnboardingSteps'
import T from 'types'
import './styles.sass'
import { DEFAULT_PATHOLOGY } from 'constants/doctorSelectionStates'

interface OnBoardingProps extends RouteComponentProps<any> {
  loaded: boolean
  termsOfService: boolean
  loading: boolean
  collection: any[]
  availableChannels: string[]
  storefront: T.Storefront
  consultation: T.Consultation | null
  consultationType: string
  consultationPaymentSuccess: boolean | undefined
  doctor: T.Doctor
  survey: Model
  surveyId: number
  surveyFetchedAt?: Date
  returnFromThreeDSecure?: boolean
  socialSecurityMode: boolean
  isRecovery: boolean
  questionName: string
  patientAttributes: object
  order: T.OrderV1 | null
  slot: Date
  skipDoctorSelection: boolean
  invitationToken: string | null
  invitationAcceptedAt: Date | null
  medium: string
  createMessage: (userId: number, body: string, patientUserId: number) => void
  setTermsOptin: () => void
  getDoctors: () => void
  updatePatientData: (data: any) => void
  getCommunicationChannels: () => void
  createConsultation: (onCreateSuccess: () => void, onSlotUnavailable: () => void) => void
  getSurvey: (
    {
      pdid,
      email,
      orderNumber,
    }: {
      pdid?: string
      email?: string
      orderNumber?: string
    },
  ) => void
  setSurveyQuestionName: (question: string) => void
  setSurveyObject: (survey: object) => void
  getPaymentMethods: () => void
  acceptInvitation: (data: any, onSuccess: () => void) => void
  uploadSurvey: (orderNumber: string | undefined, survey: object) => void
  setPersonalData: (data: object) => void
  createPatient: (data: object, source: string) => void
  setChosenTimeSlot: (value: Date) => void
  setChosenDoctor: (doctor: T.Doctor) => void
  setChosenMedium: (medium: string) => void
  setChosenConsultType: (type: string) => void
  setOpenConsultationDoctor: (slug: string | undefined) => void
  setSocialSecurityMode: (socialSecurityMode?: boolean) => void
  sendConsultationPayment: (
    {
      data,
      orderNumber,
      onPaymentSuccess,
      onPaymentError
    }: {
      data: T.PaymentPayload
      orderNumber: string
      onPaymentSuccess: () => void
      onPaymentError: () => void
    },
  ) => void
  setFocusedSpecialty: (specialty: string) => void
  updateConsultationWithSSN: (onCreateSuccess: () => void) => void
  didStartPrePaymentSurvey: () => void
}

const SURVEY_RESET_TIMER = 60 * 1000 * 30

// export type T.OnboardingParams = {
//   spe?: string
//   pdid?: string
//   email?: string
//   orderNumber?: string
//   refresh?: boolean
//   returnFromThreeDSecure?: boolean
// }

const PAGE_WAS_REFRESHED = 'PAGE_WAS_REFRESHED'
const PAGE_WAS_RESTORED_TOO_LATE = 'PAGE_WAS_RESTORED_TOO_LATE'
const PAGE_NEEDS_LOADING = 'PAGE_NEEDS_LOADING'
const UNKNOWN_CONFIG = 'UNKNOWN_CONFIG'

type OnBoardingState = {
  initialized: boolean
}

class OnBoarding extends Component<OnBoardingProps, OnBoardingState> {

  constructor(props: OnBoardingProps) {
    super(props);
    this.state = {
      initialized: false,
    }
  }
  public componentDidMount() {
    const params = this.parseQueryString(window.location.search)
    this.initializeOnboarding(params)
    tracker.trackDidEnterOnboarding(true)
    tracker.trackProductViewed(`${params.spe}`)

    const eventName = 'onpagehide' in window ? 'pagehide' : 'unload'
    window.addEventListener(eventName, this.handleWindowUnload, false)
  }
  public componentWillUnmount() {
    const eventName = 'onpagehide' in window ? 'pagehide' : 'unload'
    window.removeEventListener(eventName, this.handleWindowUnload, false)
  }

  handleWindowUnload = (e: any) => {
    const { patientAttributes } = this.props
    const email = get(patientAttributes, 'email')
    // Perf hardening CHAR-1110
    // if (!isUndefined(email)) {
    //   ApiService.cleanDrop(email)
    // }
  }

  private detectLaunchConfig(
    params: T.OnboardingParams,
    survey: Model,
    surveyFetchedAt: Date | undefined,
  ): string {
    if (
      params.refresh ||
      params.pdid ||
      params.orderNumber ||
      params.returnFromThreeDSecure ||
      params.email ||
      params.d
    ) {
      return PAGE_NEEDS_LOADING
    }
    if (
      // !params.refresh &&
      survey
    ) {
      if (surveyFetchedAt && moment().diff(moment(surveyFetchedAt)) < SURVEY_RESET_TIMER) {
        return PAGE_WAS_REFRESHED
      } else {
        return PAGE_WAS_RESTORED_TOO_LATE
      }
    } else {
      return UNKNOWN_CONFIG
    }
  }

  private initializeOnboarding(params: T.OnboardingParams) {
    const {
      getSurvey,
      setTermsOptin,
      setFocusedSpecialty,
      setOpenConsultationDoctor,
      setSocialSecurityMode,
      surveyFetchedAt,
      survey,
    } = this.props

    const url = new URL(window.location.toString())
    setTermsOptin()
    if (params.spe) {
      // Detect if change of spe ? and get in a different configuration ?
      setFocusedSpecialty(params.spe)
    }
    setOpenConsultationDoctor(params.d)
    setSocialSecurityMode(params.socialSecurityMode)

    const currentConfiguration = this.detectLaunchConfig(params, survey, surveyFetchedAt)
    switch (currentConfiguration) {
      case PAGE_WAS_REFRESHED:
        break
      case PAGE_NEEDS_LOADING:
        getSurvey(params)
        break
      case PAGE_WAS_RESTORED_TOO_LATE:
        sessionStorage.clear()
        url.hash = ''
        url.searchParams.append('refresh', 'true')
        window.location.href = url.toString()
        return
        break
      case UNKNOWN_CONFIG:
      default:
        sessionStorage.clear()
        url.hash = ''
        url.searchParams.append('refresh', 'true')
        window.location.href = url.toString()
        return
    }

    this.setState({ initialized: true })
    url.searchParams.delete('refresh')
    window.history.replaceState({}, document.title, url.toString())
  }

  private parseQueryString(queryString: string): T.OnboardingParams {
    const searchParams = new URLSearchParams(queryString)
    const values = {
      spe: searchParams.get('spe'),
      pdid: searchParams.get('pdid'),
      email: searchParams.get('email'),
      orderNumber: searchParams.get('orderNumber'),
      refresh: searchParams.get('refresh'),
      returnFromThreeDSecure: searchParams.get('returnFromThreeDSecure'),
      socialSecurityMode: (searchParams.get('securite_sociale') === '1' ? true : false),
      d: searchParams.get('d'),
    }

    return omitBy(values, isNil)
  }

  render() {
    const { loaded, consultationPaymentSuccess, returnFromThreeDSecure } = this.props
    const { initialized } = this.state
    const specialty = this.parseQueryString(window.location.search).spe || DEFAULT_PATHOLOGY
    const returnFrom3DS = returnFromThreeDSecure || consultationPaymentSuccess

    return (initialized && loaded) ? (
      <OnboardingSteps
        {...this.props}
        returnFrom3DS={returnFrom3DS}
        prefixStepSurvey="pre_"
        prefixSurveyFinish="pre_"
        specialty={specialty}
      />
    ) : (
      <Loader />
    )
  }
}

export default OnBoarding
